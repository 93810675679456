.widget-settings-types {
  margin-bottom: 30px;
}
.widget-settings-types__list {
  margin-top: 30px;
  margin-bottom: 30px;
}
.widget-settings-types__message-list-is-empty {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.widget-settings-types__item {
  display: flex;
  margin-bottom: 40px;
}
.widget-settings-types__item-scenarios {
  width: 100%;
  margin-left: 30px;
}
