.w-bots-item-mask {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  transition-duration: 0.3s;
  outline: none;
  cursor: pointer;
}
.w-bots-item-mask_is-active {
  background-color: rgba(255,255,255,0.7);
}
.w-bots-item-mask__timer {
  width: 100%;
  height: 58px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  color: #b93c4a;
}
