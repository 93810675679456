.widget-settings-scenarios__list-title {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
}
.widget-settings-scenarios__list {
  margin-top: 20px;
  margin-bottom: 20px;
}
.widget-settings-scenarios__message-list-is-empty {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.widget-settings-scenarios__item {
  display: flex;
  margin-bottom: 15px;
}
