.w-bots-item {
  max-width: 90px;
  padding: 2px 2px 0;
  position: relative;
  transition-duration: 0.3s;
}
.w-bots-item_theme_default.w-bots-item {
  flex-direction: column;
}
.w-bots-item_theme_priority.w-bots-item {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 160px;
  background-color: #f9f9fb;
  padding: 15px 20px;
}
.w-bots-item_theme_priority.w-bots-item[disabled],
.w-bots-item_theme_priority.w-bots-item[data-is-disabled],
.w-bots-item_theme_priority.w-bots-item.slick-disabled {
  cursor: default;
}
.w-bots-item__link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition-duration: 0.3s;
}
.w-bots-item_theme_default .w-bots-item__media {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #f9f9fb;
  position: relative;
  color: #808080;
  box-sizing: border-box;
}
.w-bots-item_theme_default .w-bots-item__media[disabled],
.w-bots-item_theme_default .w-bots-item__media[data-is-disabled],
.w-bots-item_theme_default .w-bots-item__media.slick-disabled {
  cursor: default;
}
.w-bots-item_theme_priority .w-bots-item__media {
  margin-right: 10px;
}
.w-bots-item__status {
  width: 10px;
  height: 10px;
  background-color: #ddd;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -2px;
}
.w-bots-item__status_is-subscribed {
  background-color: #93d700;
}
.w-bots-item__status_is-disabled {
  background-color: #b93c4a;
}
.w-bots-item__title {
  color: #444;
  text-align: center;
}
.w-bots-item_theme_default .w-bots-item__title {
  margin-top: 8px;
  font-size: 12px;
  line-height: 1;
}
.w-bots-item_theme_priority .w-bots-item__title {
  font-size: 18px;
  line-height: 1;
}
