.control-input {
  display: flex;
}
.control-input_direction_vertical {
  flex-direction: column;
}
.control-input_direction_horizontal {
  flex-direction: row;
}
.control-input_is-disabled {
  cursor: not-allowed;
}
.control-input_is-disabled.control-input > label {
  pointer-events: none;
}
.control-input_direction_vertical .control-input__label-before {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1;
}
.control-input_direction_horizontal .control-input__label-before {
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
}
.control-input__container {
  width: 100%;
}
.control-input__container > input:disabled {
  pointer-events: none;
}
.control-input__label-after {
  margin-left: 10px;
}
