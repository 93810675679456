@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'), local('.LucidaGrandeUI'), local('Ubuntu Light'), local('Segoe UI Light'), local('Roboto-Light'), local('DroidSans'), local('Tahoma');
}
.tabs-component {
  border-bottom: 1px solid #428bca;
  margin-bottom: 30px;
}
.tabs-component-tabs {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  border: 0;
  border-bottom: 1px solid #428bca;
  margin-bottom: -1px;
}
.tabs-component-tab {
  background: 0 0;
  color: #999;
  font-size: 14px;
  font-weight: 600;
  list-style: none;
  margin-right: 0.5em;
  transform: translateY(1px);
  transition: transform 0.3s ease;
}
.tabs-component-tab:hover {
  color: #666;
}
.tabs-component-tab.is-active {
  color: #000;
  z-index: 2;
}
.tabs-component-tab.is-active::after {
  border-bottom: 11px solid #fff;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: '';
  display: inline-block;
  position: absolute;
  right: calc(50% - 11px);
  bottom: 0;
}
.tabs-component-tab.is-active::before {
  border-bottom: 11px solid #428bca;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: '';
  display: inline-block;
  position: absolute;
  right: calc(50% - 11px);
  bottom: 1px;
}
.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}
.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 10px 12px 20px;
  text-decoration: none;
  transition-duration: 0.3s;
}
.tabs-component-panels {
  padding: 30px 15px;
}
.ViberCmsC {
  cursor: pointer;
  margin: 10px 10px 10px 7px;
  padding: 10px 10px 10px 7px;
}
.ViberCmsC::checked ~ label::after {
  opacity: 1;
}
.ViberCmsC::focus ~ label::before {
  box-shadow: 0 0 0 3px rgba(255,255,0,0.7);
}
.ViberCmsC ~ label {
  position: relative;
  padding: 0 0 0 35px;
  cursor: pointer;
}
.ViberCmsCHide ~ label::before {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #cdd1da;
  border-radius: 50%;
  background: #fff;
}
.ViberCmsCHide ~ label::after {
  content: '';
  position: absolute;
  top: 1px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #9fd468;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.5);
  opacity: 0;
  transition: 0.2s;
}
@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'), local('.LucidaGrandeUI'), local('Ubuntu Light'), local('Segoe UI Light'), local('Roboto-Light'), local('DroidSans'), local('Tahoma');
}
body {
  overflow: hidden;
}
