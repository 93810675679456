.w-vcard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 15px;
  position: relative;
}
.w-vcard__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.w-vcard__item {
  max-width: 60px;
  padding: 2px 2px 0;
  position: relative;
  transition-duration: 0.3s;
}
.w-vcard__link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition-duration: 0.3s;
  color: #428bca;
}
.w-vcard__link:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #428bca;
}
.w-vcard__link:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.w-vcard__link[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #428bca;
}
.w-vcard__media {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #f9f9fb;
  position: relative;
  box-sizing: border-box;
}
.w-vcard__media[disabled],
.w-vcard__media[data-is-disabled],
.w-vcard__media.slick-disabled {
  cursor: default;
}
.w-vcard__title {
  margin-top: 8px;
  font-size: 12px;
  line-height: 1;
  color: #444;
}
