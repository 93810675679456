.widget-settings {
  height: 100%;
  padding: 30px 15px;
  margin-left: -10px;
  margin-right: -10px;
  overflow-y: auto;
}
.widget-settings__container {
  max-width: 1600px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
