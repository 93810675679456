.control-color-picker_is-disabled {
  cursor: not-allowed;
}
.control-color-picker_is-disabled.control-color-picker > label {
  pointer-events: none;
}
.control-color-picker__container {
  display: inline-block;
  width: 300px;
  position: relative;
}
.control-color-picker__button-toggle-picker {
  cursor: pointer;
  transition-duration: 0.3s;
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  width: 32px;
  border-left: 1px solid #ccc;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  box-shadow: inset 0 0 0 4px #fff;
  outline: none;
}
.control-color-picker__picker-wrap {
  display: none;
  position: absolute;
  right: 0;
  bottom: 100%;
  z-index: 1000;
}
.control-color-picker_is-picker-opened .control-color-picker__picker-wrap {
  display: block;
}
.control-color-picker__label-after {
  margin-left: 10px;
}
