.widget-editor-property-pane {
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid #eee;
}
.widget-editor-property-pane__header {
  border-bottom: 1px dashed #eee;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}
.widget-editor-property-pane__button-close {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.widget-editor-property-pane__title {
  font-size: 14px;
  line-height: 1.714285714285714;
  text-align: center;
}
.widget-editor-property-pane__body {
  height: 100%;
  padding: 20px 15px 10px;
}
.widget-editor-property-pane__body_is-empty {
  background-color: #f7f7f7;
}
.widget-editor-property-pane__item {
  margin-bottom: 15px;
}
.widget-editor-property-pane__item:last-child {
  margin-bottom: 0;
}
