.widget-settings-scenarios-item {
  width: 100%;
  position: relative;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.25);
  transition-duration: 0.3s;
}
.widget-settings-scenarios-item_is-active {
  border-color: #999;
}
.widget-settings-scenarios-item__debug-box {
  border-bottom: 1px solid #fff;
  padding: 5px 10px;
}
.widget-settings-scenarios-item__controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  position: absolute;
  top: -10px;
  left: -15px;
  z-index: 11;
}
.widget-settings-scenarios-item__wrap {
  display: flex;
  width: 100%;
}
.widget-settings-scenarios-item__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  cursor: not-allowed;
}
.widget-settings-scenarios-item__row {
  border-right: 1px solid #eee;
  padding: 10px;
}
.widget-settings-scenarios-item__row:last-child {
  border-width: 0;
}
.widget-settings-scenarios-item__row_description {
  width: 100%;
}
.widget-settings-scenarios-item__row_conditions {
  flex-shrink: 0;
  width: 200px;
}
.widget-settings-scenarios-item__row_where {
  flex-shrink: 0;
  width: 140px;
}
.widget-settings-scenarios-item__row_when {
  flex-shrink: 0;
  width: 140px;
}
.widget-settings-scenarios-item__row_counters {
  flex-shrink: 0;
  width: 140px;
}
.widget-settings-scenarios-item__button-toggle,
.widget-settings-scenarios-item__button-edit,
.widget-settings-scenarios-item__button-save,
.widget-settings-scenarios-item__button-cancel,
.widget-settings-scenarios-item__button-delete {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 50%;
  margin-bottom: 8px;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
  outline: none;
}
.widget-settings-scenarios-item__button-toggle:disabled,
.widget-settings-scenarios-item__button-edit:disabled,
.widget-settings-scenarios-item__button-save:disabled,
.widget-settings-scenarios-item__button-cancel:disabled,
.widget-settings-scenarios-item__button-delete:disabled {
  background-color: #edeeee;
  color: #81858a;
}
.widget-settings-scenarios-item__button-toggle:not(.widget-settings-scenarios-item__button-toggle_is-active) {
  background-color: #fff;
}
.widget-settings-scenarios-item__button-toggle:not(.widget-settings-scenarios-item__button-toggle_is-active):hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5cb85c;
}
.widget-settings-scenarios-item__button-toggle:not(.widget-settings-scenarios-item__button-toggle_is-active):active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-toggle:not(.widget-settings-scenarios-item__button-toggle_is-active)[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5cb85c;
}
.widget-settings-scenarios-item__button-toggle:not(.widget-settings-scenarios-item__button-toggle_is-active) svg,
.widget-settings-scenarios-item__button-toggle:not(.widget-settings-scenarios-item__button-toggle_is-active) i {
  opacity: 0.25;
}
.widget-settings-scenarios-item__button-toggle:hover.widget-settings-scenarios-item__button-toggle:not(.widget-settings-scenarios-item__button-toggle_is-active) svg,
.widget-settings-scenarios-item__button-toggle:hover.widget-settings-scenarios-item__button-toggle:not(.widget-settings-scenarios-item__button-toggle_is-active) i {
  color: #fff;
  opacity: 1;
}
.widget-settings-scenarios-item__button-toggle_is-active {
  background-color: #fff;
  color: #5cb85c;
}
.widget-settings-scenarios-item__button-toggle_is-active:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-scenarios-item__button-toggle_is-active:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-toggle_is-active[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-scenarios-item__button-toggle_is-active:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-toggle_is-active:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-toggle_is-active[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-edit {
  background-color: #fff;
  color: #5bc0de;
  position: relative;
}
.widget-settings-scenarios-item__button-edit:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.widget-settings-scenarios-item__button-edit:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-edit[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.widget-settings-scenarios-item__button-edit:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-edit:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-edit[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-edit_is-blink {
  animation-duration: 0.5s;
  animation-name: widget-settings-scenarios-item-button-blink;
}
.widget-settings-scenarios-item__button-edit_is-blink::before {
  content: '';
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  box-shadow: 0 0 3px 1px #5bc0de;
  animation-duration: 0.5s;
  animation-name: widget-settings-scenarios-item-button-before-blink;
}
.widget-settings-scenarios-item__button-save {
  background-color: #fff;
  color: #5cb85c;
}
.widget-settings-scenarios-item__button-save:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5cb85c;
}
.widget-settings-scenarios-item__button-save:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-save[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5cb85c;
}
.widget-settings-scenarios-item__button-save:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-save:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-save[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-cancel {
  background-color: #fff;
  color: #d9534f;
}
.widget-settings-scenarios-item__button-cancel:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-scenarios-item__button-cancel:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-cancel[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-scenarios-item__button-cancel:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-cancel:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-cancel[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-delete {
  background-color: #fff;
  color: #d9534f;
}
.widget-settings-scenarios-item__button-delete:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-scenarios-item__button-delete:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-delete[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-scenarios-item__button-delete:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-delete:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-scenarios-item__button-delete[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-scenarios-item__button-reset-counters {
  margin-top: 15px;
}
.widget-settings-scenarios-item__condition-box {
  display: flex;
}
.widget-settings-scenarios-item__condition-box:not(:last-child) {
  margin-bottom: 10px;
}
.widget-settings-scenarios-item__condition-box-name,
.widget-settings-scenarios-item__condition-box-enabled,
.widget-settings-scenarios-item__condition-box-installed {
  display: flex;
  justify-content: center;
  align-items: center;
}
.widget-settings-scenarios-item__condition-box-name {
  width: 100%;
}
.widget-settings-scenarios-item__condition-box-enabled {
  flex-shrink: 0;
  width: 40px;
}
.widget-settings-scenarios-item__condition-box-installed {
  flex-shrink: 0;
  width: 60px;
}
.widget-settings-scenarios-item__condition-box-hint {
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.166666666666667;
}
div .control-textarea__container textarea .form-control {
  background: #fff;
}
@-moz-keyframes widget-settings-scenarios-item-button-blink {
0% {
    background-color: #5bc0de;
    color: #fff;
}
100% {
    background-color: #fff;
    color: #5bc0de;
}
}
@-webkit-keyframes widget-settings-scenarios-item-button-blink {
0% {
    background-color: #5bc0de;
    color: #fff;
}
100% {
    background-color: #fff;
    color: #5bc0de;
}
}
@-o-keyframes widget-settings-scenarios-item-button-blink {
0% {
    background-color: #5bc0de;
    color: #fff;
}
100% {
    background-color: #fff;
    color: #5bc0de;
}
}
@keyframes widget-settings-scenarios-item-button-blink {
0% {
    background-color: #5bc0de;
    color: #fff;
}
100% {
    background-color: #fff;
    color: #5bc0de;
}
}
@-moz-keyframes widget-settings-scenarios-item-button-before-blink {
0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
}
100% {
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    opacity: 0;
}
}
@-webkit-keyframes widget-settings-scenarios-item-button-before-blink {
0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
}
100% {
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    opacity: 0;
}
}
@-o-keyframes widget-settings-scenarios-item-button-before-blink {
0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
}
100% {
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    opacity: 0;
}
}
@keyframes widget-settings-scenarios-item-button-before-blink {
0% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
}
100% {
    top: -15px;
    right: -15px;
    bottom: -15px;
    left: -15px;
    opacity: 0;
}
}
