.w-a2hs {
  padding: 10px 25px;
  position: relative;
}
.w-a2hs__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.w-a2hs__presentation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.w-a2hs__icon-arrow {
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  top: -10px;
}
.w-a2hs__app-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.w-a2hs__app-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
  overflow: hidden;
}
.w-a2hs__app-logo {
  width: 56px;
  height: 56px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.w-a2hs__app-name {
  margin-top: 8px;
  font-size: 11px;
  line-height: 1;
}
.w-a2hs__icon-smartphone {
  color: #999;
}
.w-a2hs__button-a2hs {
  padding: 20px 0px 20px 5px;
  border: 0;
  border-radius: 0;
  color: #4682ea;
  font-size: 18px;
  background: none;
  font-weight: 500;
}
.w-a2hs__button-a2hs:focus,
.w-a2hs__button-a2hs:active {
  outline: none;
}
.w-a2hs__button-action {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
  outline: none;
  background: #3876f6;
  background: -moz-linear-gradient(-45deg, #5662d6 0%, #3876f6 100%);
  background: -webkit-linear-gradient(-45deg, #5662d6 0%, #3876f6 100%);
  background: linear-gradient(135deg, #5662d6 0%, #3876f6 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#3876f6', endColorstr='#5662d6', GradientType=1)";
  display: flex;
  align-items: center;
  padding: 15px 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  line-height: 1;
  color: #fff;
}
.w-a2hs__button-action[disabled],
.w-a2hs__button-action[data-is-disabled],
.w-a2hs__button-action.slick-disabled {
  cursor: default;
}
.w-a2hs__button-action:hover,
.w-a2hs__button-action:active {
  background: #5662d6;
  background: -moz-linear-gradient(-45deg, #3876f6 0%, #5662d6 100%);
  background: -webkit-linear-gradient(-45deg, #3876f6 0%, #5662d6 100%);
  background: linear-gradient(135deg, #3876f6 0%, #5662d6 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#5662d6', endColorstr='#3876f6', GradientType=1)";
}
.w-a2hs__button-action > svg {
  transition-duration: 0.3s;
}
.w-a2hs__button-action svg + span {
  margin-top: 2px;
  margin-left: 10px;
}
.w-a2hs__button-action span {
  margin-top: 2px;
  margin-left: 10px;
  text-transform: uppercase;
}
.w-a2hs__icon-play {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
}
.w-a2hs_line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.w-a2hs_line .w-a2hs__presentation {
  margin-bottom: 0;
  margin-left: 0;
}
.w-a2hs-modal__instruction {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-a2hs-modal__instruction img {
  max-width: 100%;
  max-height: 100%;
}
.w-a2hs-modal__button-close-instruction {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  background-color: rgba(185,60,74,0.25);
  border: 1px solid rgba(185,60,74,0.35);
  color: rgba(185,60,74,0.6);
  position: absolute;
  top: 50%;
  left: 0;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.3);
}
