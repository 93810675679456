.widget-settings-types-item {
  width: 200px;
  position: relative;
}
.widget-settings-types-item__card {
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.25);
  transition-duration: 0.3s;
}
.widget-settings-types-item_is-active .widget-settings-types-item__card {
  border-color: #71c171;
}
.widget-settings-types-item__controls {
  position: absolute;
  top: -8px;
  left: -14px;
  z-index: 1;
}
.widget-settings-types-item__header {
  display: flex;
  align-items: center;
  padding: 10px 10px 8px 20px;
}
.widget-settings-types-item__name {
  margin-right: 10px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
}
.widget-settings-types-item__info {
  display: flex;
  margin-left: auto;
}
.widget-settings-types-item__count-scenarios,
.widget-settings-types-item__button-toggle,
.widget-settings-types-item__button-setup,
.widget-settings-types-item__button-delete {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 1;
}
.widget-settings-types-item__count-scenarios {
  background-color: #fff;
  margin-left: 5px;
  cursor: default;
}
.widget-settings-types-item__button-toggle,
.widget-settings-types-item__button-setup,
.widget-settings-types-item__button-delete {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition-duration: 0.3s;
  margin-bottom: 8px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.3);
  outline: none;
}
.widget-settings-types-item__button-toggle:disabled,
.widget-settings-types-item__button-setup:disabled,
.widget-settings-types-item__button-delete:disabled {
  background-color: #edeeee;
  color: #81858a;
}
.widget-settings-types-item__button-toggle:not(.widget-settings-types-item__button-toggle_is-active) {
  background-color: #fff;
}
.widget-settings-types-item__button-toggle:not(.widget-settings-types-item__button-toggle_is-active):hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5cb85c;
}
.widget-settings-types-item__button-toggle:not(.widget-settings-types-item__button-toggle_is-active):active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-types-item__button-toggle:not(.widget-settings-types-item__button-toggle_is-active)[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5cb85c;
}
.widget-settings-types-item__button-toggle:not(.widget-settings-types-item__button-toggle_is-active) svg,
.widget-settings-types-item__button-toggle:not(.widget-settings-types-item__button-toggle_is-active) i {
  opacity: 0.25;
}
.widget-settings-types-item__button-toggle:hover.widget-settings-types-item__button-toggle:not(.widget-settings-types-item__button-toggle_is-active) svg,
.widget-settings-types-item__button-toggle:hover.widget-settings-types-item__button-toggle:not(.widget-settings-types-item__button-toggle_is-active) i {
  color: #fff;
  opacity: 1;
}
.widget-settings-types-item__button-toggle_is-active {
  background-color: #fff;
  color: #5cb85c;
}
.widget-settings-types-item__button-toggle_is-active:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-types-item__button-toggle_is-active:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-types-item__button-toggle_is-active[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-types-item__button-toggle_is-active:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-types-item__button-toggle_is-active:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-types-item__button-toggle_is-active[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-types-item__button-setup {
  background-color: #fff;
  color: #5bc0de;
}
.widget-settings-types-item__button-setup:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.widget-settings-types-item__button-setup:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-types-item__button-setup[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.widget-settings-types-item__button-setup:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-types-item__button-setup:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-types-item__button-setup[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-types-item__button-delete {
  background-color: #fff;
  color: #d9534f;
}
.widget-settings-types-item__button-delete:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-types-item__button-delete:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-types-item__button-delete[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-settings-types-item__button-delete:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-types-item__button-delete:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-settings-types-item__button-delete[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-settings-types-item__preview {
  padding: 5px 10px 10px;
  font-size: 16px;
  line-height: 1.5em;
  zoom: 0.5;
  position: relative;
  overflow: hidden;
  opacity: 0.4;
  transition: 0.3s;
}
.widget-settings-types-item_is-active .widget-settings-types-item__preview {
  opacity: 1;
}
.widget-settings-types-item__preview::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.widget-settings-types-item__widget-modal-inner-base {
  box-shadow: 0 8px 20px -1px rgba(27,33,58,0.3);
}
.widget-settings-types-item__types {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.widget-settings-types-item__types-item {
  background-color: rgba(91,192,222,0.25);
  border-radius: 5px;
  padding: 3px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 1;
}
.widget-settings-types-item__types-item:last-child {
  margin-right: 0;
}
