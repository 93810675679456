.widget-editor-content-area-component-controls {
  display: flex;
  background: #fff;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 12px;
  line-height: 1;
  transition-duration: 0.3s;
  box-shadow: 0 1px 5px 1px rgba(0,0,0,0.2);
}
.widget-editor-content-area-component-controls__button-duplicate,
.widget-editor-content-area-component-controls__button-delete {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  padding: 4px 2px;
  transition-duration: 0.3s;
  outline: none;
}
.widget-editor-content-area-component-controls__button-duplicate {
  background-color: transparent;
  color: #5bc0de;
}
.widget-editor-content-area-component-controls__button-duplicate:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.widget-editor-content-area-component-controls__button-duplicate:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-editor-content-area-component-controls__button-duplicate[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.widget-editor-content-area-component-controls__button-duplicate:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-editor-content-area-component-controls__button-duplicate:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-editor-content-area-component-controls__button-duplicate[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-editor-content-area-component-controls__button-delete {
  background-color: transparent;
  color: #d9534f;
}
.widget-editor-content-area-component-controls__button-delete:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-editor-content-area-component-controls__button-delete:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-editor-content-area-component-controls__button-delete[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.widget-editor-content-area-component-controls__button-delete:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.widget-editor-content-area-component-controls__button-delete:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-editor-content-area-component-controls__button-delete[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
