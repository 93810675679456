.control-file {
  position: relative;
}
.control-file svg {
  display: block;
  fill: currentColor;
}
.control-file input[type=file] {
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
.control-file__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.control-file__label {
  display: block;
  font-size: 14px;
  line-height: 1.285714285714286;
}
.control-file_has-value .control-file__label {
  width: 100%;
  margin-bottom: 10px;
}
.control-file_is-error .control-file__label {
  color: #b93c4a;
}
.control-file__body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}
.control-file_is-multiple .control-file__body {
  margin-left: -10px;
  margin-right: -10px;
}
.control-file__item {
  flex-shrink: 0;
  width: 100%;
  position: relative;
}
.control-file_is-multiple .control-file__item {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.control-file_is-multiple .control-file__item:last-child {
  margin-bottom: 0;
}
.control-file__preview {
  display: block;
  height: 160px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.control-file__info {
  border-left: 4px solid #c0c0c0;
  padding-left: 8px;
  font-size: 12px;
  line-height: 1.333333333333333;
}
.control-file__preview + .control-file__info {
  margin-top: 10px;
}
.control-file__button-remove-item {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition-duration: 0.3s;
  background-color: rgba(217,83,79,0.35);
  color: rgba(255,255,255,0.35);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.control-file__button-remove-item:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.control-file__button-remove-item:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-file__button-remove-item[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #d9534f;
}
.control-file__button-remove-item:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.control-file__button-remove-item:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-file__button-remove-item[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.control-file__controls {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: auto;
}
.control-file_has-value .control-file__controls {
  width: 100%;
}
.control-file__button-add,
.control-file__button-remove-all {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0 !important;
}
.control-file__button-add span + svg,
.control-file__button-remove-all span + svg,
.control-file__button-add svg + span,
.control-file__button-remove-all svg + span {
  margin-left: 8px;
}
.control-file__button-remove-all {
  margin-left: 15px;
}
.control-file__service-message {
  margin-top: 10px;
}
