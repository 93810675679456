.control-select_is-disabled {
  cursor: not-allowed;
}
.control-select_is-disabled.control-select > label {
  pointer-events: none;
}
.control-select__label-before {
  margin-bottom: 10px;
}
.control-select__container > select {
  width: 100%;
  height: 38px;
}
.control-select__container > select:disabled {
  pointer-events: none;
}
.control-select__label-after {
  margin-left: 10px;
}
