.widget-editor-content-area-component {
  position: relative;
  cursor: pointer;
}
.widget-editor-content-area-component_is-active {
  box-shadow: 0 0 0 1px #f00;
}
.widget-editor-content-area-component:hover {
  box-shadow: 0 0 0 1px #eee;
}
.widget-editor-content-area-component_is-active.widget-editor-content-area-component:hover {
  box-shadow: 0 0 0 1px #f00;
}
.widget-editor-content-area-component__controls {
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 10;
  opacity: 0;
}
.widget-editor-content-area-component:hover .widget-editor-content-area-component__controls {
  opacity: 0.8;
}
.widget-editor-content-area-component__controls:hover,
.widget-editor-content-area-component_is-active .widget-editor-content-area-component__controls {
  opacity: 1 !important;
}
