.widget-editor-content-area__list {
  min-height: 20px;
}
.widget-editor-content-area__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f0f0f0;
  border: 1px dashed #ccc;
  font-size: 14px;
  line-height: 1.285714285714286;
  color: #4f5154;
}
