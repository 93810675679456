.widget-settings-scenarios-head {
  display: flex;
  width: 100%;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.15);
  transition-duration: 0.3s;
}
.widget-settings-scenarios-head__row {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 0 solid #aadeee;
  padding: 5px;
  font-weight: 700;
}
.widget-settings-scenarios-head__row:not(:last-child) {
  border-width: 1px;
}
.widget-settings-scenarios-head__row > span {
  margin-right: 10px;
}
.widget-settings-scenarios-head__row_description {
  width: 100%;
}
.widget-settings-scenarios-head__row_conditions {
  flex-shrink: 0;
  width: 200px;
}
.widget-settings-scenarios-head__row_where {
  flex-shrink: 0;
  width: 140px;
}
.widget-settings-scenarios-head__row_when {
  flex-shrink: 0;
  width: 140px;
}
.widget-settings-scenarios-head__row_counters {
  flex-shrink: 0;
  width: 140px;
}
