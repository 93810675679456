.control-counter_is-disabled {
  cursor: not-allowed;
}
.control-counter_is-disabled.control-counter > label {
  pointer-events: none;
}
.control-counter__label-before {
  margin-bottom: 10px;
}
.control-counter__container {
  position: relative;
}
.control-counter__container > input {
  padding-right: 32px;
}
.control-counter__container > input:disabled {
  pointer-events: none;
}
.control-counter__controls {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.control-counter__button-increment,
.control-counter__button-decrement {
  cursor: pointer;
  transition-duration: 0.3s;
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 19px;
  border: 1px solid transparent;
  outline: none;
  background-color: #fff;
  border-color: #ccc;
  color: #4e5154;
}
.control-counter__button-increment:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-decrement:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.control-counter__button-increment:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-decrement:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-increment[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-decrement[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.control-counter__button-increment:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-decrement:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  border-color: #5bc0de;
}
.control-counter__button-increment:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-decrement:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-increment[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-decrement[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  border-color: #5bc0de;
}
.control-counter__button-increment:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-decrement:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.control-counter__button-increment:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-decrement:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-increment[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-counter__button-decrement[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.control-counter__button-increment:disabled,
.control-counter__button-decrement:disabled {
  color: #a5a8ab;
  opacity: 0.65;
  pointer-events: none;
}
.control-counter__button-increment {
  border-bottom: 0;
  border-top-right-radius: 2px;
}
.control-counter__button-decrement {
  border-bottom-right-radius: 2px;
}
.control-counter__label-after {
  margin-left: 10px;
}
