.control-checkbox-list__label-before {
  margin-bottom: 20px;
}
.control-checkbox-list__item:not(:last-child) {
  margin-bottom: 10px;
}
.control-checkbox-list__hint {
  margin-top: 20px;
  color: #777;
}
