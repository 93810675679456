.hint-helper {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: #fff;
  color: #4e5154;
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 50%;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
}
.hint-helper:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.hint-helper:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.hint-helper[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: #5bc0de;
}
.hint-helper:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
.hint-helper:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.hint-helper[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #fff;
}
