.control-textarea_is-disabled {
  cursor: not-allowed;
}
.control-textarea_is-disabled.control-textarea > label {
  pointer-events: none;
}
.control-textarea__label-before {
  margin-bottom: 10px;
}
.control-textarea__container > textarea {
  min-height: 100px;
  resize: vertical;
}
.control-textarea__container > textarea:disabled {
  pointer-events: none;
}
.control-textarea__label-after {
  margin-left: 10px;
}
