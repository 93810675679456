.widget-editor-component-palette {
  width: 300px;
  border: 1px solid #eee;
}
.widget-editor-component-palette__title {
  border-bottom: 1px dashed #eee;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.714285714285714;
  text-align: center;
}
.widget-editor-component-palette__list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
}
.widget-editor-component-palette__component {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 20px;
  cursor: pointer;
  transition-duration: 0.3s;
  background-color: transparent;
  cursor: grab;
}
.widget-editor-component-palette__component:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: rgba(91,192,222,0.15);
}
.widget-editor-component-palette__component:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-editor-component-palette__component[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  background-color: rgba(91,192,222,0.15);
}
.widget-editor-component-palette__component-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.widget-editor-component-palette__component-name {
  margin-top: 5px;
  text-align: center;
}
