.widget-modal-inner-base {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #444;
  position: relative;
}
.widget-modal-inner-base__button-close {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
  outline: none;
  border-radius: 50%;
  color: #4e5154;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -5px;
  right: -8px;
}
.widget-modal-inner-base__button-close[disabled],
.widget-modal-inner-base__button-close[data-is-disabled],
.widget-modal-inner-base__button-close.slick-disabled {
  cursor: default;
}
.widget-modal-inner-base__button-close:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #b93c4a;
}
.widget-modal-inner-base__button-close:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.widget-modal-inner-base__button-close[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #b93c4a;
}
.widget-modal-inner-base__button-close > svg {
  transition-duration: 0.3s;
}
.widget-modal-inner-base__button-close:hover > svg {
  transform: rotate(180deg);
}
.widget-modal-inner-base__header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  background-color: #3876f6;
  padding: 15px 20px;
  color: #fff;
}
.widget-modal-inner-base__header-icon {
  margin-right: 15px;
}
.widget-modal-inner-base__header-title {
  font-size: 20px;
  line-height: 1.2;
}
.widget-modal-inner-base__body {
  padding-top: 10px;
  padding-bottom: 10px;
}
.widget-modal-inner-base__footer {
  flex-shrink: 0;
  padding: 10px 15px;
}
.widget-modal-inner-base__controls {
  display: flex;
  justify-content: space-evenly;
}
.widget-modal-inner-base__button-link {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  display: block;
  padding: 10px;
  font-size: 12px;
  line-height: 1;
  color: #337ab7;
  text-align: center;
  outline: none;
}
