.control-checkbox {
  display: flex;
  position: relative;
}
.control-checkbox_is-disabled {
  cursor: not-allowed;
}
.control-checkbox > input {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.control-checkbox > label {
  margin-bottom: 0 !important;
}
.control-checkbox_is-disabled.control-checkbox > label {
  pointer-events: none;
}
.control-checkbox__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;
  box-sizing: border-box;
}
.control-checkbox_theme_button-radial .control-checkbox__checkbox {
  border-radius: 50%;
}
.control-checkbox_theme_button-radial.control-checkbox:hover .control-checkbox__checkbox {
  background-color: #76ac00;
}
.control-checkbox_is-checked.control-checkbox_theme_button-radial.control-checkbox:hover .control-checkbox__checkbox {
  background-color: #b93c4a;
}
.control-checkbox_is-disabled .control-checkbox__checkbox {
  background-color: #eee;
}
.control-checkbox_has-fail .control-checkbox__checkbox {
  border-color: #d9534f;
}
.control-checkbox__icon-check {
  transition-duration: 0.3s;
}
.control-checkbox_theme_default .control-checkbox__icon-check {
  opacity: 0;
}
.control-checkbox_theme_button-radial .control-checkbox__icon-check {
  opacity: 0.25;
}
.control-checkbox_theme_default.control-checkbox:hover .control-checkbox__icon-check {
  opacity: 0.25;
}
.control-checkbox_theme_button-radial.control-checkbox:hover .control-checkbox__icon-check {
  color: #fff;
  opacity: 1;
}
.control-checkbox_theme_default.control-checkbox_is-checked .control-checkbox__icon-check {
  opacity: 1;
}
.control-checkbox_theme_button-radial.control-checkbox_is-checked .control-checkbox__icon-check {
  color: #76ac00;
  opacity: 1;
}
.control-checkbox_theme_default.control-checkbox:hover.control-checkbox_is-checked .control-checkbox__icon-check {
  opacity: 0.65;
}
.control-checkbox_is-disabled.control-checkbox_is-checked .control-checkbox__icon-check {
  opacity: 0.5 !important;
}
.control-checkbox_is-disabled .control-checkbox__icon-check {
  opacity: 0 !important;
}
.control-checkbox__label {
  display: block;
  min-height: 24px;
  padding-top: 5px;
  padding-left: 10px;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  word-break: break-all;
  cursor: pointer;
  user-select: none;
}
.control-checkbox_is-disabled .control-checkbox__label {
  color: #b4b4b4;
}
.control-checkbox__label a {
  cursor: pointer;
  transition-duration: 0.3s;
  color: #000;
}
.control-checkbox__label a:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #000;
}
.control-checkbox__label a:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.control-checkbox__label a[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #000;
}
