.widget-editor {
  max-width: 1400px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.widget-editor__controls {
  height: 26px;
  margin-bottom: 15px;
}
.widget-editor__button-back {
  display: flex;
  align-items: center;
}
.widget-editor__button-back span {
  margin-left: 5px;
}
.widget-editor__button-edit-modal {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.widget-editor__workspace {
  display: flex;
  margin-top: 15px;
}
.widget-editor__demo-modal-container {
  margin-left: auto;
  margin-right: auto;
}
.widget-editor__widget-editor-demo-modal-wrap {
  height: 100%;
  padding: 15px;
  background-color: rgba(0,0,0,0.5);
  overflow-y: auto;
}
.widget-editor__widget-editor-demo-modal-wrap_is-editing-modal {
  box-shadow: 0 0 0 1px #f00;
}
.widget-editor__property-pane-container {
  padding-top: 41px;
}
.widget-editor__widget-type-properties {
  margin-bottom: 30px;
}
.widget-editor__demo-modal-properties {
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid #eee;
}
.widget-editor__demo-modal-properties-header {
  border-bottom: 1px dashed #eee;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}
.widget-editor__demo-modal-properties-button-close {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.widget-editor__demo-modal-properties-title {
  font-size: 14px;
  line-height: 1.714285714285714;
  text-align: center;
}
.widget-editor__demo-modal-properties-body {
  padding: 20px 15px 10px;
}
.widget-editor__field {
  width: 100%;
  margin-bottom: 15px;
}
.widget-editor__field:last-child {
  margin-bottom: 0;
}
.widget-editor__field-code-box {
  background-color: rgba(91,192,222,0.3);
  border-radius: 3px;
  padding: 5px 10px;
  margin-top: 10px;
}
