.w-bots {
  padding: 10px 15px;
  position: relative;
}
.w-bots__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.w-bots__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: -15px;
}
.w-bots__item {
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 15px;
  opacity: 1;
  transform: scale(1);
  transition: 0.225s ease-in-out;
}
.w-bots__item.is-hide {
  opacity: 0.25;
  transform: scale(0.65);
}
