.w-image {
  padding: 10px 15px;
}
.w-image__image {
  display: block;
  width: 100%;
}
.w-image_is-default .w-image__image {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
