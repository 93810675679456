.w-bots-code {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #5bc0de;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
  overflow: initial !important;
}
.w-bots-code__button-close {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  box-sizing: border-box;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
  outline: none;
  border-radius: 50%;
  color: #4e5154;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}
.w-bots-code__button-close[disabled],
.w-bots-code__button-close[data-is-disabled],
.w-bots-code__button-close.slick-disabled {
  cursor: default;
}
.w-bots-code__button-close:hover:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #b93c4a;
}
.w-bots-code__button-close:active:not([disabled]):not([data-is-disabled]):not(.slick-disabled),
.w-bots-code__button-close[data-is-active]:not([disabled]):not([data-is-disabled]):not(.slick-disabled) {
  color: #b93c4a;
}
.w-bots-code__button-close > svg {
  transition-duration: 0.3s;
}
.w-bots-code__button-close:hover > svg {
  transform: rotate(180deg);
}
.w-bots-code__columns {
  display: flex;
  flex-direction: column;
}
.w-bots-code__code {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 6px;
  font-size: 50px;
  line-height: 1;
  line-height: normal;
  font-weight: 700;
  color: #fff;
  letter-spacing: 12px;
}
.w-bots-code__help-text {
  font-size: 28px;
  line-height: 1;
  text-decoration: underline;
  color: #fff;
  text-align: center;
}
.w-bots-code__fake-button {
  width: 50px;
  height: 50px;
}
.w-bots-code__button-copy,
.w-bots-code__button-refresh {
  background: 0 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition-duration: 0.3s;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  color: #fff;
  outline: 0;
}
.w-bots-code__button-copy span,
.w-bots-code__button-refresh span {
  margin-top: 5px;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
}
.w-bots-code__button-copy {
  margin-right: auto;
}
.w-bots-code__button-refresh {
  margin-left: auto;
}
.w-bots-code__hint {
  margin-top: 10px;
  font-weight: 700;
  color: #4e5154;
  text-align: center;
}
.w-bots-code__service-message {
  margin-top: 10px;
  color: #fff;
  text-align: center;
}
