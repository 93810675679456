.b-bot-settings .b-settings-box__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.b-bot-settings .b-settings-box__header a {
  font-size: 0.65em;
  background-color: rgba(66,139,202,0.25);
}
.b-bot-settings__icon {
  display: flex;
  align-items: center;
}
.b-bot-settings__icon img {
  margin-right: 40px;
  border-radius: 50%;
  object-fit: contain;
  width: 100px;
  height: 100px;
}
